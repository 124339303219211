<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>

    <!-- 表头 -->
    <div class="sec-title">优学卡红包列表</div>
    <!-- 查询
    <div class="search">
      <el-form :inline="true" :model="form">
        <el-form-item label="订单号">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="机构名称">
          <el-input v-model="form.institutionsName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="aaa">查询</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <!-- 列表 -->
    <div>
      <el-table :data="tableData" border>
        <el-table-column align="center" type="index" width="100" label="序号">
        </el-table-column>
        <el-table-column align="center" prop="user_id" label="红包金额">
        </el-table-column>
        <el-table-column
          align="center"
          prop="map.inviteUserInfo.nick_name"
          label="邀请人昵称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="map.inviteUserInfo.mobile"
          label="邀请人手机号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="map.invitationUserInfo.nick_name"
          label="被邀请人昵称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="map.invitationUserInfo.mobile"
          label="被邀请人手机号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="create_time"
          label="创建时间"
        >
        </el-table-column>
      </el-table>
      <!-- <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper, total"
        :total="total"
      ></el-pagination> -->
          <!-- 分页 -->
          <paging-fy
      @currentPageChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "活动管理中心",
        secondNav: "优学卡红包管理",
      },
      //分页
      pageSize: 10,
      currentPage: 1,
      total: 0,
      //查询
      form: { order: "", mobile: "", institutionsName: "" },
      //列表
      tableData: [],
      user: {},
    };
  },
  methods: {
    //排行列表
    GetList() {
      let url = "/user/userRecommenderIncomeLog/queryDetailList";
      this.$axios
        .get(url, {
          params: {
            type: "invite_coupon",
            pageSize: 10,
            currentPage: this.currentPage,
            source: "体育宝",
            categories: "体育运动",
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleClick(row) {
      this.$router.push({
        name: "UserInvitationList",
        query: {
          id: JSON.stringify(row),
        },
      });
    },

    handleSizeChange(val) {},

    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
  },
  mounted() {
    this.GetList();
  },
};
</script>
